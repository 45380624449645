import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { DatePicker, Radio, Skeleton, Switch, TimePicker } from 'antd'
import { ScheduleWindow } from '@pushly/models/lib/enums/schedule-window'
import './styles/app-message-schedule-builder.scss'
import { getClassNames } from '../../../_utils/classnames'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { BASE_TIME_FORMAT_WITHOUT_TZ } from '../../../constants'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { MutableAppMessageBuilderSchedulePayload } from '../model-extensions'
import { memo, useCallback } from 'react'

type AppMessageScheduleBuilderProps = {
    submitProps: Well['props']
}

const displayScheduleWindow = (value: ScheduleWindow) => {
    switch (value) {
        case ScheduleWindow.STANDARD:
            return 'Fixed'
        case ScheduleWindow.TIMEZONE:
            return 'Subscriber Time Zone'
    }
}

const getStrategyDescription = (
    runStrategy: ScheduleWindow,
    dateTimeZone: string,
    endDateEnabled: boolean,
    dateStartStr?: string,
    dateEndStr?: string,
) => {
    const descriptors: string[] = [`The app message will start on ${dateStartStr}`]
    if (endDateEnabled && dateEndStr) {
        descriptors.push(`and run through ${dateEndStr}`)
    }
    if (runStrategy === ScheduleWindow.TIMEZONE) {
        descriptors.push(`in each subscriber's time zone`)
    } else {
        descriptors.push(`in the ${dateTimeZone} time zone`)
    }

    return `${descriptors.join(' ')}.`
}
export const AppMessageScheduleBuilder = ({
    submitProps: { hideFooter, disableSubmit, onSubmit, onCancel, submitText },
}: AppMessageScheduleBuilderProps) => {
    const [context, dispatch] = useAppMessageBuilderContext()
    const { variants, selectedVariantIdx } = context

    const value = variants[selectedVariantIdx]

    const schedule = value.schedules?.[0] ?? new MutableAppMessageBuilderSchedulePayload()
    const { runDateWindow, runDateStartUtc, runDateEndUtc, runDateTimeZone } = schedule

    const now = moment()
    const today = now.clone().startOf('day')
    const dateStart = moment(runDateStartUtc)

    const [endDateEnabled, setEndDateEnabled] = React.useState(!!runDateEndUtc)
    const [displaySchedule, setDisplaySchedule] = React.useState<string>()

    const handleScheduleUpdate = (change: Partial<MutableAppMessageBuilderSchedulePayload>) => {
        const newSchedule = schedule.clone(change)

        if (change.runDateEndUtc === null) {
            newSchedule.runDateEndUtc = null
        }

        dispatch({ type: 'patch', entity: 'schedule', data: newSchedule })
    }

    const handleDateChange = (date: Moment | null, rangePoint: 'Start' | 'End') => {
        let nextDate = date
        const scheduleKey = `runDate${rangePoint}Utc`

        if (nextDate) {
            const prevDate = moment(schedule[scheduleKey])
            nextDate.hour(prevDate.hour())
            nextDate.minute(prevDate.minute())
        }

        const startDate = !nextDate ? undefined : nextDate.utc().toISOString()
        handleScheduleUpdate({ [scheduleKey]: startDate })
    }

    const handleTimeChange = (time: Moment | null, rangePoint: 'Start' | 'End') => {
        let nextDate
        const scheduleKey = `runDate${rangePoint}Utc`

        if (time) {
            nextDate = moment(schedule[scheduleKey])
            nextDate!.hour(time.hour())
            nextDate!.minute(time.minute())
            nextDate!.second(59)
        }

        const startDate = !nextDate ? undefined : nextDate.utc().toISOString()

        handleScheduleUpdate({ [scheduleKey]: startDate })
    }

    React.useEffect(() => {
        setDisplaySchedule(
            getStrategyDescription(
                runDateWindow,
                runDateTimeZone,
                endDateEnabled,
                dateStart.format(BASE_TIME_FORMAT_WITHOUT_TZ),
                runDateEndUtc ? moment(runDateEndUtc).format(BASE_TIME_FORMAT_WITHOUT_TZ) : null,
            ),
        )
    }, [schedule])

    return (
        <Well
            title="Schedule"
            className={getClassNames('app-message-schedule-builder')}
            hideFooter={hideFooter}
            disableSubmit={disableSubmit}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitText={submitText}
        >
            <Skeleton loading={context.loading} active={true} avatar={true} title={false}>
                <div className="schedule-strategy">
                    <label>
                        <span className="label-with-component-r">Strategy</span>
                        <Radio.Group
                            buttonStyle="solid"
                            className="schedule-strategy-options"
                            disabled={context.disabled}
                            onChange={(ev) => {
                                const strategy = ScheduleWindow[ev.target.value]
                                handleScheduleUpdate({ runDateWindow: strategy })
                            }}
                            size="small"
                            defaultValue={schedule.runDateWindow ?? ScheduleWindow.STANDARD}
                        >
                            {Object.values(ScheduleWindow).map((w) => (
                                <Radio.Button key={w} value={ScheduleWindow[w]}>
                                    {displayScheduleWindow(w)}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </label>
                </div>

                <div className="run-dates-row">
                    <div className="delivery-date-start">
                        <span>Schedule Start</span>
                        <div className="delivery-senddate-picker">
                            <DatePicker
                                className="delivery-date-picker"
                                format="ddd, MMM DD, YYYY"
                                placeholder="Start Date"
                                disabled={context.disabled}
                                disabledDate={(current) => !!current && current < today}
                                value={!dateStart ? moment().startOf('day') : dateStart}
                                onChange={(startDate) => {
                                    handleDateChange(startDate, 'Start')
                                }}
                            />

                            <TimePicker
                                className="delivery-time-picker"
                                format="h:mm a"
                                use12Hours={true}
                                placeholder="Time"
                                value={!dateStart ? now.clone().startOf('day') : dateStart}
                                disabled={context.disabled}
                                onChange={(startTime) => handleTimeChange(startTime, 'Start')}
                                onSelect={(startTime) => handleTimeChange(startTime, 'Start')}
                            />
                        </div>
                    </div>

                    <div className="delivery-date-end">
                        <span className="label-with-component-r">Schedule End</span>
                        <Switch
                            size="small"
                            checked={endDateEnabled}
                            disabled={context.disabled}
                            onChange={(checked) => {
                                let endDate
                                if (!checked) {
                                    endDate = null
                                } else {
                                    const seed = dateStart ? dateStart.clone() : moment()

                                    seed.add(1, 'day').endOf('day')
                                    endDate = seed.format('YYYY-MM-DD HH:mm:ss')
                                }

                                handleScheduleUpdate({ runDateEndUtc: endDate })
                                setEndDateEnabled(checked)
                            }}
                        />
                        <div className="delivery-senddate-picker">
                            <DatePicker
                                className="delivery-date-picker"
                                format="ddd, MMM DD, YYYY"
                                placeholder="End Date"
                                disabled={!endDateEnabled || context.disabled}
                                disabledDate={(current) => !!dateStart && !!current && current < dateStart}
                                value={!runDateEndUtc ? undefined : moment(runDateEndUtc)}
                                onChange={(endDate) => handleDateChange(endDate, 'End')}
                            />

                            <TimePicker
                                className="delivery-time-picker"
                                format="h:mm a"
                                use12Hours={true}
                                placeholder="Time"
                                disabled={!endDateEnabled || context.disabled}
                                disabledDate={(current) => !!dateStart && current < dateStart.clone().add(1, 'day')}
                                value={!runDateEndUtc ? undefined : moment(runDateEndUtc)}
                                onChange={(endTime) => handleTimeChange(endTime, 'End')}
                                onSelect={(endTime) => handleTimeChange(endTime, 'End')}
                            />
                        </div>
                    </div>
                </div>
                <span className="strategy-description">{displaySchedule}</span>
            </Skeleton>
        </Well>
    )
}
