import * as querystring from 'query-string'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { AccountDto } from '../dtos/account.dto'
import { simpleNotification } from '../_utils/utils'
import { DomainDto } from '../dtos/domain'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import fileDownload from 'js-file-download'
import aqe from '@pushly/aqe'
import { AbstractEntityService } from '@pushly/aqe/lib/services'
import IApiCallOptions from '../interfaces/api-call-options'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { IApiResponse } from '@pushly/aqe/lib/interfaces'
import { ApiTokenDataRecord } from '../components/api-tokens/types'
import { ApiToken } from '@pushly/models/lib/structs/api-tokens/api-token'
import { NewApiTokenRequest } from '@pushly/models/lib/structs/api-tokens/new-api-token-request'

@Singleton
export class AccountService extends AbstractEntityService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        super()

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAll(
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }

            return req.data
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return []
        }
    }

    public async fetchById(
        accountId: number,
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        let dto: AccountDto

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            dto = AccountDto.fromApiResponse(req.data.data)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return dto!
    }

    public async post(
        createDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        let dto: AccountDto

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts`

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: createDto,
                },
                cancellationKey,
            )

            dto = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', `Organization '${createDto.name}' has been successfully created.`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return dto!
    }

    public async patch(
        accountId: number,
        updateDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<AccountDto>> {
        let dto: AccountDto
        let ok = false

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}`

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: serviceURL,
                    data: updateDto,
                },
                cancellationKey,
            )

            ok = true
            dto = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', 'Your organization has been successfully updated.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: dto! }
    }

    public async fetchKeywords(accountId: number, options: IApiCallOptions & { rawOrder?: boolean } = {}) {
        if (options.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const res = await this.execFetchMany(`${aqe.defaults.publicApiDomain}/accounts/${accountId}/keywords`, options)
        if (options.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (!res.ok && res.error) {
            if (!isAxiosCancellation(res.error)) {
                simpleNotification('error', 'Organization keywords could not be loaded at this time.')
            }
        }

        return res
    }

    public async fetchSubscriberPreferences(orgId: number, options: IApiCallOptions = {}) {
        if (options.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const res = await this.execGet<{ domainId: number; preference: string }[]>(
            `${aqe.defaults.publicApiDomain}/accounts/${orgId}/subscriber-preferences`,
            options,
        )
        if (options.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (!res.ok && res.error) {
            if (!isAxiosCancellation(res.error)) {
                simpleNotification('error', 'Organization subscriber preferences could not be loaded at this time.')
            }
        }

        return res
    }

    public async fetchBillingRecordsByAccountId(
        accountId: number,
        opts: any = {},
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any[]>> {
        let ok = false
        let invoices: any = []
        let meta: any

        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/billing-records?${options}`

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            ok = true

            const responseIsCSV = /text\/csv/i.test(req.request.getResponseHeader('content-type'))
            if (responseIsCSV) {
                const csvDisposition = req.request.getResponseHeader('Content-Disposition')
                let filename = 'pushly-billing-history.csv'

                if (csvDisposition) {
                    filename = csvDisposition.match(/"([^"]+)"/i)[1]
                }

                fileDownload(req.data, filename)
            } else {
                const { data, ..._meta } = req.data
                invoices = data
                meta = _meta
            }
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: invoices, meta }
    }

    public async manageBilling(
        accountId: number,
        billingDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        // TODO: investigate why we are possibly returning an empty account object
        let account: AccountDto = {} as any

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/billing-data`

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: billingDto,
                },
                cancellationKey,
            )

            account = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', 'Your account billing has been successfully updated.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return account!
    }

    public async fetchAccountDomains(
        accountId: number,
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<DomainDto[]> {
        let domains: DomainDto[] = []

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/domains?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            domains = req.data.data.map(DomainDto.fromApiResponse)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return domains
    }

    public async fetchApiKeys(
        accountId: number,
        opts: IApiCallOptions = {},
        showLoadingScreen: boolean = true,
    ): Promise<IServiceApiResponse<ApiTokenDataRecord[]>> {
        const options = querystring.stringify(opts?.query ?? {})
        let response: IServiceApiResponse<ApiTokenDataRecord[]> = {
            ok: false,
            data: [],
        }

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/api-keys?${options}`

        try {
            const req = await axiosFetch('get', {
                url: serviceUrl,
            })

            const { data: apiTokens, ...reqMeta } = req.data
            response.ok = true
            response.data = apiTokens.map((token) => ApiToken.parseJsonObject(token))
            response.meta = reqMeta
        } catch (error) {
            response.error = error
            handleResponseErrorMessage(error, {
                onCancelled: () => (response.cancelled = true),
            })
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    public async createApiKey(
        accountId: number,
        opts?: IApiCallOptions,
        createDto?: NewApiTokenRequest,
    ): Promise<IServiceApiResponse<ApiTokenDataRecord | null>> {
        let response: IServiceApiResponse<ApiTokenDataRecord | null> = {
            ok: false,
            data: null,
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/api-keys`

        try {
            const req = await axiosFetch('post', {
                url: serviceUrl,
                data: createDto,
                config: { timeout: 15000 },
            })

            const { data: apiToken, ...reqMeta } = req.data

            response.ok = true
            response.data = apiToken as ApiTokenDataRecord
            response.meta = reqMeta
        } catch (error) {
            // Axios uses the ECONNABORTED code for request timeouts
            // In later versions (starting in 0.21.2), ETIMEDOUT can be used, but requires
            // a transitional object set on request config object as documented here:
            // https://github.com/axios/axios/issues/1543#issuecomment-1478866117
            if (error.code === 'ECONNABORTED') {
                simpleNotification(
                    'error',
                    'An error occurred when attempting to generate the API Token. Please try again or contact your account manager for help.',
                )
            } else {
                response.error = error
                handleResponseErrorMessage(error, {
                    onCancelled: () => (response.cancelled = true),
                })
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    public async revokeApiKey(
        accountId: number,
        userId: number,
        opts?: IApiCallOptions,
    ): Promise<IApiResponse<boolean>> {
        let ok = false

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/api-keys/${userId}`

        try {
            const req = await axiosFetch(
                'delete',
                {
                    url: serviceUrl,
                    config: { timeout: 15000 },
                },
                opts?.cancellationKey,
            )

            ok = true
        } catch (error) {
            // Axios uses the ECONNABORTED code for request timeouts
            // In later versions (starting in 0.21.2), ETIMEDOUT can be used, but requires
            // a transitional object set on request config object as documented here:
            // https://github.com/axios/axios/issues/1543#issuecomment-1478866117
            if (error.code === 'ECONNABORTED') {
                simpleNotification(
                    'error',
                    'An error occurred when attempting to revoke the API Token. Please try again or contact your account manager for help.',
                )
            } else {
                handleResponseErrorMessage(error)
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok }
    }

    public async regenerateApiKey(
        accountId: number,
        apiKeyId: number,
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<ApiTokenDataRecord | null>> {
        let response: IServiceApiResponse<ApiTokenDataRecord | null> = {
            ok: false,
            data: null,
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/api-keys/${apiKeyId}/regenerate`

        try {
            const req = await axiosFetch('post', {
                url: serviceUrl,
            })

            const { data: apiToken, ...reqMeta } = req.data

            response.ok = true
            response.data = apiToken as ApiTokenDataRecord
            response.meta = reqMeta
        } catch (error) {
            response.error = error
            handleResponseErrorMessage(error, {
                onCancelled: () => (response.cancelled = true),
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }
}
