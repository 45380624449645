import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { Tabs } from 'antd'
import { AppState } from '../../stores/app'
import { AccountService, AppService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import DomainDataForm from '../../components/domain-data-form/domain-data-form'
import { UserList } from '../../components/user-list/user-list'
import DomainIntegrationsManager from '../../components/domain-integrations-manager/domain-integrations-manager'
import { FEAT_DOMAIN_INTEGRATIONS } from '../../constants'
import { PageHeader } from '@pushly/aqe/lib/components'
import DomainNotificationSettings from '../../components/domain-notification-settings/domain-notification-settings'
import DomainPromptSettings from '../../components/domain-prompt-settings/domain-prompt-settings'
import DomainSegmentationSettings from '../../components/domain-segmentation-settings/domain-segmentation-settings'
import { KeywordManagerV2 } from '../../components/keyword-manager/keyword-manager-v2'
import { isDeliveryChannelEnabled } from '../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class Domain extends React.Component<any> {
    protected appState: AppState
    protected appService: AppService
    protected accountService: AccountService

    protected disposeObservers: any[] = []

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.accountService = Container.get(AccountService)
    }

    public render() {
        const hasIntegrationsFlag = this.appState.currentDomain?.flags?.includes(FEAT_DOMAIN_INTEGRATIONS)
        const domainIsWebEnabled = isDeliveryChannelEnabled(this.appState.currentDomain!, DeliveryChannel.WEB)
        return (
            <div className={getClassNames('page', 'single-domain')}>
                <div className={getClassNames('page-wrapper')}>
                    <PageHeader
                        title="Settings"
                        append={<span>ID: {this.requestedDomainId}</span>}
                        onTitleSet={this.appService.customizeTabTitle}
                    />

                    <Tabs
                        size="small"
                        animated={{ inkBar: false, tabPane: false }}
                        tabPosition="top"
                        activeKey={this.currentTabKey}
                        onChange={this.setCurrentTabKey}
                        destroyInactiveTabPane={true}
                    >
                        <Tabs.TabPane key="settings" tab="Overview">
                            <DomainDataForm key={`ddf-${this.requestedDomainId}`} domainId={this.requestedDomainId} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="users" tab="Users">
                            <UserList
                                key={`dul-${this.requestedDomainId}`}
                                options={{
                                    domainId: this.requestedDomainId,
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="notifications" tab="Notifications">
                            <DomainNotificationSettings
                                key={`ddf-${this.requestedDomainId}`}
                                domainId={this.requestedDomainId}
                            />
                        </Tabs.TabPane>
                        {domainIsWebEnabled && (
                            <Tabs.TabPane key="prompts" tab="Prompts">
                                <DomainPromptSettings
                                    key={`ddf-${this.requestedDomainId}`}
                                    domainId={this.requestedDomainId}
                                />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane key="segmentation" tab="Segmentation">
                            <DomainSegmentationSettings
                                key={`ddf-${this.requestedDomainId}`}
                                domainId={this.requestedDomainId}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="keywords" tab="Keywords">
                            <KeywordManagerV2
                                level="domain"
                                domainId={this.requestedDomainId}
                                actions={{
                                    actions: {
                                        addKeywords: true,
                                        bulkKeywordActions: true,
                                        exportKeywords: true,
                                    },
                                }}
                            />
                        </Tabs.TabPane>
                        {hasIntegrationsFlag && (
                            <Tabs.TabPane key="integrations" tab="Integrations">
                                <DomainIntegrationsManager
                                    key={`dim-${this.requestedDomainId}`}
                                    domainId={this.requestedDomainId}
                                />
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </div>
            </div>
        )
    }

    protected get requestedDomainId(): number {
        return parseInt(this.props.match.params.domainId, 10)
    }

    protected get currentTabKey(): string {
        let key = 'settings'

        if (this.props.location.hash) {
            key = this.props.location.hash.replace('#', '').toLowerCase() || key
        }

        return key
    }

    protected setCurrentTabKey = (key: string): void => {
        this.appService.route(`${this.props.location.pathname}#${key}`)
    }
}
